import * as React from "react";

import { ContentWrapper } from "../components/content-wrapper";
import { HeroImg } from "../components/hero-img";
import { HeroImgGuide } from "../components/hero-img-guide";

import { ProgramDetail } from "@topm/shared";
import { TextList } from "@topm/shared";
import { LetterBlock } from "@topm/shared";
import { Richtext } from "@topm/shared";
import { SplitVertically } from "@topm/shared";
import { TextSet } from "@topm/shared";
import { ImgTextBlock } from "@topm/shared";

import { ProfileBlock } from "../components/profile-block";
import { Layout } from "../components/layout";
import { JsxFormatter } from "../components/jsx-formatter";

import { Label } from "@topm/shared";
import { PageProps } from "gatsby";

const ComponentsPage: React.FC<PageProps> = () => {
  return (
    <Layout>
      <Label className="mt-vgap-2xl">HeroImg</Label>

      <HeroImg
        srcHeroNarrow="https://images.prismic.io/takazudo-gatsby-example/4a8faa13-ba41-4ed5-882a-ae8f3fd9882a_hero1.png?auto=compress,format"
        srcHeroWide="https://images.prismic.io/takazudo-gatsby-example/14a4e25c-1378-4c90-bb7b-01753f77af55_hero2.png?auto=compress,format"
        alt="ドラッカー 5つの質問 オンラインセミナー 5つの取り組みで経営幹部は必ず育つ。一枚岩の経営チームを作る方法"
      />

      <Label>HeroImgGuide</Label>

      <HeroImgGuide />

      <ContentWrapper className="pb-vgap-lg">
        <Label>Richtext</Label>

        <Richtext
          html={`
          <h2>彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた</h2>
          <h3>彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた</h3>
          <h4>彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた</h4>
          <p>彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない</p>
          <ul>
            <li>彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない</li>
            <li>彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない</li>
            <li>彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない</li>
          </ul>
        `}
        />

        <Label>ImgTextBlock: imgPosition="right" ulStyle="check"</Label>

        <ImgTextBlock
          imgWide="http://placekitten.com/600/400"
          imgNarrow="http://placekitten.com/300/200"
          imgPosition="right"
          ulStyle="check"
          html={`
            <h2>彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた</h2>
            <h3>彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた</h3>
            <h4>彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた</h4>
            <p>彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない</p>
            <ul>
              <li>彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない</li>
              <li>彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない</li>
              <li>彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない</li>
            </ul>
          `}
        />

        <Label>ImgTextBlock: imgPosition="left" ulStyle="bigText"</Label>

        <ImgTextBlock
          imgWide="http://placekitten.com/600/400"
          imgNarrow="http://placekitten.com/300/200"
          imgPosition="left"
          ulStyle="bigText"
          html={`
            <h2>彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた</h2>
            <h3>彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた</h3>
            <h4>彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた彼は背後にひそかな足音を聞いた</h4>
            <p>彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない</p>
            <ul>
              <li>彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない</li>
              <li>彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない</li>
              <li>彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない</li>
            </ul>
          `}
        />

        <Label>ImgTextBlock: rawHtml</Label>

        <ImgTextBlock
          imgWide="http://placekitten.com/600/400"
          imgNarrow="http://placekitten.com/300/200"
          imgPosition="left"
          html={`
            <p>JSX code here will be parsed!</p>
          `}
        />

        <Label>LetterBlock</Label>

        <LetterBlock
          html={`
          <ul>
            <li>彼は背後にひそかな</li>
            <li>足音を聞いた。</li>
            <li>それはあまり良い意味を示すものではない。</li>
            <li>誰がこんな夜更けに、</li>
            <li>しかもこんな街灯の</li>
            <li>お粗末な港街の</li>
            <li>狭い小道で</li>
            <li>彼をつけて来るというのだ。</li>
          </ul>
        `}
        />

        <Label>SplitVertically</Label>

        <SplitVertically>
          <SplitVertically.Area>
            <p>
              split content left split content left split content left split
              content left split content left split content left split content
              left split content left split content left split content left
              split content left split content left split content left split
              content left split content left split content left split content
              left split content left split content left split content left
              split content left{" "}
            </p>
          </SplitVertically.Area>
          <SplitVertically.Area>
            <p>
              split content right split content right split content right split
              content right split content right split content right split
              content right split content right split content right split
              content right split content right split content right split
              content right split content right split content right split
              content right split content right split content right split
              content right split content right split content right{" "}
            </p>
          </SplitVertically.Area>
        </SplitVertically>

        <Label>ProfileBlock</Label>

        <ProfileBlock
          section1={`
            <h2>彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。</h2>
            <h3>彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。</h3>
            <h4>山田山田</h4>
            <p>彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。人生の航路を捻じ曲げ、その獲物と共に立ち去ろうとしている、その丁度今。 彼のこの仕事への恐れを和らげるために、数多い仲間の中に同じ考えを抱き、彼を見守り、待っている者がいるというのか。それとも背後の足音の主は、この街に無数にいる法監視役で、強靭な罰をすぐにも彼の手首にガシャンと下すというのか。彼は足音が止まったことに気が着いた。あわてて辺りを見回す。ふと狭い抜け道に目が止まる。 彼は素早く右に身を翻し、建物の間に消え去った。その時彼は、もう少しで道の真中に転がっていたごみバケツに躓き転ぶところだった。 彼は暗闇の中で道を確かめようとじっと見つめた。どうやら自分の通ってきた道以外にこの中庭からの出道はないようだ。 足音はだんだん近づき、彼には角を曲がる黒い人影が見えた。彼の目は夜の闇の中を必死にさまよい、逃げ道を探す。もうすべては終わりなのか。すべての苦労と準備は水の泡だというのか。 突然、彼の横で扉が風に揺らぎ、ほんのわずかにきしむのを聞いた時、彼は背中を壁に押し付け、追跡者に見付けられないことを願った。この扉は望みの綱として投げかけられた、彼のジレンマからの出口なのだろうか。背中を壁にぴったり押し付けたまま、ゆっくりと彼は開いている扉の方へと身を動かして行った。この扉は彼の救いとなるのだろうか。</p>
            <ul>
              <li>トップマネジメント株式会社 代表取締役</li>
              <li>デジタルハリウッド大学院・客員教授</li>
              <li>淑徳大学・経営学講師</li>
            </ul>
          `}
          section2={`
            <h3>彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。</h3>
            <p>彼は背後にひそかな足音を聞いた。</p>
            <ul>
              <li>トップマネジメント株式会社 代表取締役</li>
              <li>デジタルハリウッド大学院・客員教授</li>
              <li>淑徳大学・経営学講師</li>
            </ul>
          `}
          section3={`
            <h3>彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。</h3>
            <p>彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。人生の航路を捻じ曲げ、その獲物と共に立ち去ろうとしている、その丁度今。 彼のこの仕事への恐れを和らげるために、数多い仲間の中に同じ考えを抱き、彼を見守り、待っている者がいるというのか。それとも背後の足音の主は、この街に無数にいる法監視役で、強靭な罰をすぐにも彼の手首にガシャンと下すというのか。彼は足音が止まったことに気が着いた。あわてて辺りを見回す。ふと狭い抜け道に目が止まる。 彼は素早く右に身を翻し、建物の間に消え去った。その時彼は、もう少しで道の真中に転がっていたごみバケツに躓き転ぶところだった。 彼は暗闇の中で道を確かめようとじっと見つめた。どうやら自分の通ってきた道以外にこの中庭からの出道はないようだ。 足音はだんだん近づき、彼には角を曲がる黒い人影が見えた。彼の目は夜の闇の中を必死にさまよい、逃げ道を探す。もうすべては終わりなのか。すべての苦労と準備は水の泡だというのか。 突然、彼の横で扉が風に揺らぎ、ほんのわずかにきしむのを聞いた時、彼は背中を壁に押し付け、追跡者に見付けられないことを願った。この扉は望みの綱として投げかけられた、彼のジレンマからの出口なのだろうか。背中を壁にぴったり押し付けたまま、ゆっくりと彼は開いている扉の方へと身を動かして行った。この扉は彼の救いとなるのだろうか。</p>
            <p>彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。人生の航路を捻じ曲げ、その獲物と共に立ち去ろうとしている、その丁度今。 彼のこの仕事への恐れを和らげるために、数多い仲間の中に同じ考えを抱き、彼を見守り、待っている者がいるというのか。それとも背後の足音の主は、この街に無数にいる法監視役で、強靭な罰をすぐにも彼の手首にガシャンと下すというのか。彼は足音が止まったことに気が着いた。あわてて辺りを見回す。ふと狭い抜け道に目が止まる。 彼は素早く右に身を翻し、建物の間に消え去った。その時彼は、もう少しで道の真中に転がっていたごみバケツに躓き転ぶところだった。 彼は暗闇の中で道を確かめようとじっと見つめた。どうやら自分の通ってきた道以外にこの中庭からの出道はないようだ。 足音はだんだん近づき、彼には角を曲がる黒い人影が見えた。彼の目は夜の闇の中を必死にさまよい、逃げ道を探す。もうすべては終わりなのか。すべての苦労と準備は水の泡だというのか。 突然、彼の横で扉が風に揺らぎ、ほんのわずかにきしむのを聞いた時、彼は背中を壁に押し付け、追跡者に見付けられないことを願った。この扉は望みの綱として投げかけられた、彼のジレンマからの出口なのだろうか。背中を壁にぴったり押し付けたまま、ゆっくりと彼は開いている扉の方へと身を動かして行った。この扉は彼の救いとなるのだろうか。</p>
          `}
        />

        <Label>TextSet</Label>

        <TextSet
          html={`
          <h4>対象者</h4>
          <p>彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。</p>
          <ul>
            <li>彼は背後にひそかな足音を聞いた</li>
            <li>彼は背後にひそかな足音を聞いた</li>
            <li>彼は背後にひそかな足音を聞いた</li>
          </ul>
        `}
        />
        <TextSet
          html={`
          <h4>対象者</h4>
          <p>彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。</p>
          <ul>
            <li>彼は背後にひそかな足音を聞いた</li>
            <li>彼は背後にひそかな足音を聞いた</li>
            <li>彼は背後にひそかな足音を聞いた</li>
          </ul>
        `}
        />

        <Label>ProgramDetail</Label>

        <ProgramDetail className="pb-vgap-lg">
          <ProgramDetail.Item n="1" title="3つの問いで事業が決まる">
            <ol>
              <li>
                経営理念とは:
                <br /> 社会に対する根本的な想い
              </li>
              <li>
                ミッションとは:
                <br /> わが社が実現したいこと
              </li>
              <li>ビジョンは目指すべき旗印</li>
            </ol>
          </ProgramDetail.Item>
          <ProgramDetail.Item n="2" title="2つの問いで事業が進む">
            <ol>
              <li>成果とは顧客で起こる良い変化</li>
              <li>成果をどう測定するか</li>
              <li>
                助成金を活用した
                <br />
                ドラッカー講座のご案内
              </li>
            </ol>
          </ProgramDetail.Item>
        </ProgramDetail>

        <Label>RegistrationNav</Label>

        <JsxFormatter jsx={`<RegistrationNav />`} />

        <Label>TextList</Label>

        <TextList className="-mt-vgap-md">
          <TextList.Item title="運営会社">
            <p>
              株式会社サンケイ会館
              <br />
              東京都千代田区大手町1-7-2 東京サンケイビル運営会社
            </p>
          </TextList.Item>
          <TextList.Item title="お問合せ">
            <p>
              事務局：小此木、近野
              <br />
              Mail：
              <a href="mailto:sankeibldg@topmanagement-boss.jp">
                sankeibldg@topmanagement-boss.jp
              </a>
            </p>
          </TextList.Item>
        </TextList>

        <Label>AddressBlock</Label>

        <JsxFormatter jsx={`<AddressBlock className="pb-vgap-xl" />`} />
      </ContentWrapper>

      <div className="h-[30vh]"></div>
    </Layout>
  );
};

export default ComponentsPage;
